/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import BanWords from "./components/Tables";
import { BANWORDS_TYPE } from "utils/enum";
// import ImportExcel from "./components/ImportExcel";
// import TableSync from "./components/TableSync";

const View = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Banwords</h4>
          </div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="List ban words">
              <BanWords type={BANWORDS_TYPE.NORMAL}/>
            </TabPanel>
            <TabPanel header="List hash tag">
              <BanWords type={BANWORDS_TYPE.HASH_TAG}/>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function(prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
