/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useCallback,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import CodeService from "services/codes";
import { Calendar } from "primereact";
import { TYPE_CODE, TYPE_VOUCHER } from "utils/enum";
import debounce from "lodash.debounce";
import VUpload from "components/v-upload";
import CategoryService from "services/categories";
import { TreeSelect } from "primereact/treeselect";
import VUserDropdown from "components/v-user";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [categories, setCategories] = useState([]);
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "updatedAt",
    sortOrder: -1,
  });
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    status: "enable",
  });

  const emptyData = {
    name: "",
    link: "",
    status: "enable",
    is_used: false,
    price: 0,
    key: "",
    used: 0,
    number: 0,
    start_date: null,
    end_date: null,
    stock: "vnex",
    type: "code",
    type_voucher: "amount",
    icon: "",
    img: "",
    category_id: [],
    is_only_for_products: false,
    user_id: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [categoriesSelected, setCategoriesSelected] = useState<any>([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  useEffect(() => {
    if (data && data?.category_id?.length > 0) {
      const categoriesObject = data.category_id.reduce((acc: any, id: string) => {
        acc[id] = {
          checked: true,
          partialChecked: false,
        };
        return acc;
      }, {});
  
      setCategoriesSelected(categoriesObject);
    }
  }, [data?.category_id]);

  const onInputChange = (value, name) => {
    console.log({ value });

    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const handleSelectCategories = (value) => {
    const newValue = Object.keys(value);
    setCategoriesSelected(value)
    onInputChange(newValue, "category_id")
  }

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CodeService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await CodeService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await CategoryService.getAll({
        query: {
          ...globalFilter,
          group: "product_category_normal",
          isTree: true,
        },
      });
      if (res) {
        setCategories(transformNodes(res));
      } else setCategories([]);
    } catch (error) {
      setCategories([]);
    }
  };

  const transformNodes = (nodes) => {
    return nodes.map((node) => ({
      ...node,
      key: node.id,
      ...(node.children?.length > 0
        ? { children: transformNodes(node.children) }
        : {}),
    }));
  };
  
  const onFilter = (name, value) => {
    // if (name === "location") {
    //   setLocationFilter(value);
    // } else {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    // }
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onInputChange(e.target.value, "key")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">User</label>
        <VUserDropdown
          value={details.user_id}
          setValue={(e) => onInputChange(e, "user_id")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="logo">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onInputChange(e.target.value, "link")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Number</label>
        <InputNumber
          id="number"
          value={details.number}
          onChange={(e) => onInputChange(e.value, "number")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Value</label>
        <InputNumber
          id="price"
          value={details.price}
          onChange={(e) => onInputChange(e.value, "price")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onInputChange(file, "icon")}
          />
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label>Image</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.img}
            setUrlFile={(file) => onInputChange(file, "img")}
          />
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Used</label>
        <InputNumber
          id="used"
          value={details.used}
          onChange={(e) => onInputChange(e.value, "used")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
          ]}
          onChange={(e) => onInputChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(TYPE_CODE).map((k) => ({
            label: k,
            value: TYPE_CODE[k],
          }))}
          onChange={(e) => onInputChange(e.value, "type")}
          optionLabel="label"
          placeholder="Type"
          className={"w-full"}
          showClear
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type Voucher</label>
        <Dropdown
          value={details.type_voucher}
          options={Object.keys(TYPE_VOUCHER).map((k) => ({
            label: k,
            value: TYPE_VOUCHER[k],
          }))}
          onChange={(e) => onInputChange(e.value, "type_voucher")}
          optionLabel="label"
          placeholder="Type Voucher"
          disabled={details.type === TYPE_CODE.CODE}
          className={"w-full"}
          showClear
        />
      </div>

      <div className="field col-6 md:col-6">
        <label htmlFor="swap">Start Date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={details.start_date}
          onChange={(e: any) => onInputChange(e.value, "start_date")}
          showTime
          showSeconds
          showButtonBar
        />{" "}
      </div>
      <div className="field col-6 md:col-6">
        <label htmlFor="swap">End Date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={details.end_date}
          onChange={(e: any) => onInputChange(e.value, "end_date")}
          showTime
          showSeconds
          showButtonBar
        />{" "}
      </div>
      <div className="field col-12">
        <TreeSelect
          placeholder="Select Categories"
          inputId="treeselect"
          value={categoriesSelected}
          onChange={(e) => handleSelectCategories(e.value)}
          options={categories}
          selectionMode="checkbox"
          display="chip"
          metaKeySelection={false}
        />
      </div>
      {/* On off option */}
      <div className="field col-6 md:col-3">
        <label htmlFor="swap">Only for products</label>
        <br />
        <InputSwitch
          checked={details.is_only_for_products}
          onChange={(e) => onInputChange(e.value, "is_only_for_products")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="swap">Is Used</label>
        <br />
        <InputSwitch
          checked={details.is_used}
          onChange={(e) => onInputChange(e.value, "is_used")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
