/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import VUpload from "components/v-upload";
import NotificationTemplateService from "services/notification-templates";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    body: "",
    image: "",
    title: "",
    key: "",
    url: "",
    sound: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await NotificationTemplateService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await NotificationTemplateService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={details.title}
          onChange={(e) => onChange("title", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="url">URL {`{from_id} {to_id} {object_id}`}</label>
        <InputText
          id="url"
          value={details.url}
          onChange={(e) => onChange("url", e.target.value)}
          required
        />
      </div>
      <div className="field col-12">
        <label htmlFor="">Key : {`{from_name} {to_name} {object_name} {qty} {stock} {time} {description}`}</label>
        <InputTextarea
          id="title"
          value={details.body}
          onChange={(e) => onChange("body", e.target.value)}
          required
        ></InputTextarea>
      </div>
      <div className="field col-12 ">
        <label htmlFor="sound">Sound</label>
        <VUpload
          urlFile={details.sound}
          fileType="audio"
          subFileType="mpeg3"
          setUrlFile={(f) => onChange("sound", f)}
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="title">Image</label>
        <VUpload
          urlFile={details.image}
          setUrlFile={(f) => onChange("image", f)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
