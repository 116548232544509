/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import Service from "services/form-templates";
import SettingService from "services/settings";
import { Panel } from "primereact/panel";
import CategoryFilterDetails from "components/v-filter";
import { InputText } from "primereact/inputtext";
import VUploads from "components/v-uploads";
// import { InputTextarea } from "primereact/inputtextarea";
import VEditor from "components/v-editor";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;
  const refDetailCategory = useRef(null);

  const emptyData = {
    name: "",
    key: "",
    type: "welcome",
    fields: [],
    object_type: "",
    option_id: "",
    attachments: {
      enable: true,
      guide: null,
    },
    images: [],
    category_filter_id: null,
    description: null,
  };

  const [details, setDetails] = useState(emptyData);
  const [objects, setObjects] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onInputChange = (e, name, index?) => {
    const val = e?.value || (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const getDataObject = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "objects",
      },
    });
    if (res && res.setting) {
      setObjects(res.setting.value);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    getDataObject();
  }, []);

  const addImages = (files) => {
    setDetails((detail) => ({
      ...detail,
      images: [...detail.images, ...files],
    }));
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="key">Select Object</label>
        <Dropdown
          value={details.object_type}
          onChange={(e) => onInputChange(e, "object_type")}
          options={objects?.map((el) => ({
            value: el.key,
            name: el.key,
          }))}
          optionLabel="name"
          placeholder="Select Type"
        />
      </div>
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onInputChange(e, "category_filter_id")}
        />
      </div>
      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Title</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Key</label>
        <InputText
          id="name"
          value={details.key}
          onChange={(e) => onInputChange(e, "key")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <VUploads
          accept={"image/*, video/*"}
          urlFile={details.images[0]}
          setUrlFiles={(file) => addImages(file)}
        />
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="attachments">Content</label>
        <div className="field col-12 md:col-12">
          <label htmlFor="attachments">Guild</label>
          <br />
          <VEditor
            value={details.description}
            onChange={(e) => onInputChange({ value: e?.html }, "description")}
            haveData={details.description ? true : false}
          />
        </div>
        {/* <InputTextarea
          id="description"
          value={details.description}
          onChange={(e) => onInputChange(e, "description")}
        ></InputTextarea> */}
      </div>
    </div>
  );
};

export default forwardRef(Details);
