/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { downloadFile, showToast } from "utils/common";
import { forwardRef } from "react";
import BanwordService from "services/ban-word";
import { Dropdown } from "primereact/dropdown";
import { InputNumber, Panel } from "primereact";
import { InputSwitch } from "primereact/inputswitch";
import CategoryFilterDetails from "components/v-filter";
import { Button } from "primereact/button";
import VDialog from "components/v-dialog";
import ImportOptions from "components/v-import-options";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { BANWORDS_TYPE } from "utils/enum";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    content: [],
    name: "",
    link: "",
    image: "",
    status: "enable",
    category_filter_id: null,
    order: 0,
    is_prioritize: false,
    type: data?.type || BANWORDS_TYPE.NORMAL,
  };

  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);
  const refDialogDetail = useRef(null);
  const refDetail = useRef(null);
  const fileRef = useRef(null);
  const [keySearch, setKeySearch] = useState("");

  const [filterKeywords, setFilterKeywords] = useState([]);

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  // const toCapitalize = (s) => {
  //   return s.charAt(0).toUpperCase() + s.slice(1);
  // };

  const importExcelFunc = (e) => {
    const file = e.files[0];
    import("xlsx")
      .then((xlsx) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const wb = xlsx.read(e.target.result, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });
          // Prepare DataTable
          // const cols: any = data[0];
          data.shift();
          // let _importedCols = cols.map((col) => ({
          //   field: col.toLowerCase(),
          //   header: toCapitalize(col),
          // }));
          let dataImport = [];
          data
            .map((i, index) => {
              if (i.length > 0) {
                let rowArray = i?.map((e) => {
                  return { keyword: e instanceof String ? e?.trim() : e };
                });
                dataImport = [...dataImport, ...rowArray];
              }
            })
            .filter(Boolean);

          setDetails({
            ...details,
            content: [...details.content, ...dataImport],
          });
        };

        reader.readAsArrayBuffer(file);
      })
      .finally(e.options.clear());

    showToast(toast, "success", "File imported!");
  };

  useEffect(() => {
    if (data && data?._id?.length > 0)
      setDetails({
        ...data,
      });
  }, [data]);

  useEffect(() => {
    if (details) setFilterKeywords(details.content);
  }, [details]);

  useEffect(() => {
    if (keySearch) {
      let filterKW = details?.content?.filter((e) => e?.keyword === keySearch);
      setFilterKeywords(filterKW);
    } else {
      setFilterKeywords(details?.content);
    }
  }, [keySearch]);

  const onChange = (name, value, index?: number) => {
    let _details = { ...details };
    if (name === "content") {
      _details[`${name}`] = _details[`${name}`].map((el, i) =>
        index === i
          ? {
              name: value,
            }
          : el
      );
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  // const handleAddOptions = () => {
  //   setDetails({
  //     ...details,
  //     content: details.content.concat({
  //       keyword: "",
  //     }),
  //   });
  // };

  const handleSubIndexOptions = (value) => {
    setDetails({
      ...details,
      content: details.content.filter((el) => el.keyword !== value),
    });
  };

  const handleImportOptions = () => {
    refDialogDetail.current.show();
  };

  const handleImport = (data) => {
    setDetails({
      ...details,
      content: [...data?.map((op) => ({ keyword: op })), ...details.content],
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data._id?.length > 0) {
        await BanwordService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await BanwordService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeValue = (key, value) => {
    let contents = details.content?.map((e) => {
      if (e.keyword === key) {
        return { keyword: value };
      } else {
        return e;
      }
    });

    setDetails({
      ...details,
      content: contents,
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => handleSubIndexOptions(rowData.keyword)}
        />
      </div>
    );
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>

      <div className="field col-12 md:col-3">
        <label htmlFor="endDate">Prioritize</label>
        <InputSwitch
          checked={details.is_prioritize}
          onChange={(e) => onChange("is_prioritize", e.value)}
        />
      </div>

      {details.type === BANWORDS_TYPE.HASH_TAG && (
        <div className="field col-12 md:col-3">
          <label htmlFor="type">Is Hashtag</label>
          <InputSwitch
            checked={details.type === BANWORDS_TYPE.HASH_TAG}
            disabled={true}
            // onChange={(e) => onChange("is_prioritize", e.value)}
          />
        </div>
      )}

      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <div className="flex col-12 align-items-center">
          Option:
          {/* <div className="flex ml-2">
            <Button
              label="+"
              className="p-button-primary py-1"
              onClick={() => handleAddOptions()}
            />
          </div> */}
          <Button
            label="Import"
            style={{
              width: "100px",
            }}
            className="p-button-primary py-1 ml-1 flex justify-content-center"
            onClick={() => handleImportOptions()}
          />
          <div className="flex align-items-center justify-content-center py-2">
            <FileUpload
              ref={fileRef}
              chooseOptions={{
                label: "Import Excel",
                icon: "pi pi-file-excel",
                className: "p-button-success",
              }}
              mode="basic"
              name="demo[]"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="ml-2"
              uploadHandler={importExcelFunc}
              customUpload={true}
              cancelOptions={cancelOptions}
            />
          </div>
          <div className="flex align-items-center justify-content-center ml-2">
            <Button
              label="Download Template"
              icon="pi pi-download"
              className="p-button-help"
              onClick={() =>
                downloadFile(
                  "admin/406bd189-5e3f-4f52-a6d8-a383f2871b53.xlsx",
                  "Import_Ban_Keywords_Template.xlsx"
                )
              }
            />
          </div>
        </div>
        <Card>
          <div className="field col-12">
            <label htmlFor="key_search">Filter</label>
            <InputText
              id="key_search"
              value={keySearch}
              onChange={(e) => setKeySearch(e.target.value)}
              placeholder="Filter Ban words"
            />
          </div>
          <DataTable
            value={filterKeywords}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            showGridlines
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column
              field="keyword"
              header="Keyword"
              body={(rowData) => (
                <InputText
                  value={rowData.keyword}
                  onChange={(e) =>
                    onChangeValue(rowData.keyword, e.target.value)
                  }
                ></InputText>
              )}
            ></Column>
            <Column
              frozen
              alignFrozen="right"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "50px" }}
            ></Column>
          </DataTable>
        </Card>

        {/* {details.content?.map((el, index) => (
          <div className="flex mb-2">
            <InputTextarea
              id="content"
              value={el}
              onChange={(e) => onChange("content", e.target.value, index)}
              required
              autoFocus
            />
            <div>
              <Button
                label="-"
                className="p-button-primary py-1 ml-1"
                onClick={() => handleSubIndexOptions(index)}
              />
            </div>
          </div>
        ))} */}
      </div>
      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
      <VDialog
        ref={refDialogDetail}
        header="Options"
        onSubmit={onSumitDialogDetail}
      >
        <ImportOptions
          ref={refDetail}
          setDataImport={handleImport}
          onCancel={onCancelDialogDetail}
        />
      </VDialog>
    </div>
  );
};

export default forwardRef(Details);
