/* eslint-disable react-hooks/exhaustive-deps */
import VDialog from "components/v-dialog";
import ImportOptions from "components/v-import-options";
import VUpload from "components/v-upload";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CategoryFieldService from "services/category-field";
import { showToast } from "utils/common";

const Details = (props, ref) => {
  const { data, reload, toast, categoryField, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    logo: "",
    key: "",
    link: "",
    status: "enable",
    order: 0,
    parent_id: null,
    hashtags: null,
  };

  const refDialogDetail = useRef(null);
  const refDetail = useRef(null);
  const [keySearch, setKeySearch] = useState("");

  const [filterKeywords, setFilterKeywords] = useState([]);

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        type: data.type || "normal",
      });
  }, [data]);

  useEffect(() => {
    if (details) setFilterKeywords(details.hashtags);
  }, [details]);

  useEffect(() => {
    if (keySearch) {
      let filterKW = details?.hashtags?.filter((e) => e?.keyword === keySearch);
      setFilterKeywords(filterKW);
    } else {
      setFilterKeywords(details?.hashtags);
    }
  }, [keySearch]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CategoryFieldService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await CategoryFieldService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const handleImportOptions = () => {
    refDialogDetail.current.show();
  };

  const handleImport = (data) => {
    setDetails({
      ...details,
      hashtags: [...data?.map((op) => ({ keyword: op })), ...details.hashtags],
    });
  };

  const onChangeValue = (key, value) => {
    let hashtags = details.hashtags?.map((e) => {
      if (e.keyword === key) {
        return { keyword: value };
      } else {
        return e;
      }
    });

    setDetails({
      ...details,
      hashtags: hashtags,
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => handleSubIndexOptions(rowData.keyword)}
        />
      </div>
    );
  };

  const handleSubIndexOptions = (value) => {
    setDetails({
      ...details,
      hashtags: details.hashtags.filter((el) => el.keyword !== value),
    });
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="name">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={categoryField}
          optionLabel="name"
          optionValue="_id"
          filter
          showClear
          onChange={(e) => onChange("parent_id", e.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onChange("link", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="link">Order</label>
        <InputNumber
          id="link"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12">
        <VUpload
          urlFile={details.logo}
          setUrlFile={(file) => onChange("logo", file)}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="content">Hashtags</label>
        <div className="flex col-12 align-items-center">
          <Button
            label="Import"
            style={{
              width: "100px",
            }}
            className="p-button-primary py-1 ml-1 flex justify-content-center"
            onClick={() => handleImportOptions()}
          />
        </div>
        <Card>
          <div className="field col-12">
            <label htmlFor="key_search">Filter</label>
            <InputText
              id="key_search"
              value={keySearch}
              onChange={(e) => setKeySearch(e.target.value)}
              placeholder="Filter Hash Tag"
            />
          </div>
          <DataTable
            value={filterKeywords}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            showGridlines
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column
              field="keyword"
              header="Keyword"
              body={(rowData) => (
                <InputText
                  value={rowData.keyword}
                  onChange={(e) =>
                    onChangeValue(rowData.keyword, e.target.value)
                  }
                ></InputText>
              )}
            ></Column>
            <Column
              frozen
              alignFrozen="right"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "50px" }}
            ></Column>
          </DataTable>
        </Card>
      </div>
      <VDialog
        ref={refDialogDetail}
        header="Options"
        onSubmit={onSumitDialogDetail}
      >
        <ImportOptions
          ref={refDetail}
          setDataImport={handleImport}
          onCancel={onCancelDialogDetail}
        />
      </VDialog>
    </div>
  );
};

export default forwardRef(Details);
