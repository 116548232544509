/* eslint-disable react-hooks/exhaustive-deps */
import VConfirm from "components/v-confirm";
import VDialog from "components/v-dialog";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { MasterContext } from "contexts/MasterContext";
import debounce from "lodash.debounce";
import {
  Calendar,
  FileUpload,
  InputSwitch,
  InputText,
  Toolbar,
} from "primereact";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { TreeSelect } from "primereact/treeselect";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import CategoryService from "services/categories";
import ProductService from "services/products";
import {
  convertNumberToVnd,
  formatLocalTime,
  formatNumber,
  getStringLimit,
  getUrlImage,
  selectEmpty,
  showToast,
} from "utils/common";
import { PRODUCT_STATUS } from "utils/enum";
import ProductDetail from "./components/Details";
import ProductReward from "./components/Reward";

const View = () => {
  const query: any = new URLSearchParams(useLocation().search);
  const categoryId = query?.get("category");
  const { productCategories: categories } = useContext(MasterContext);
  const [filterCategories, setFilterCategories] = useState(categories);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detail, setDetail] = useState(null);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState([]);

  const refReward = useRef(null);
  const refDialogReward = useRef(null);

  const refDialogDetail = useRef(null);

  const refDialogDelete = useRef(null);

  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState({
    status: "enable",
    search: null,
    start: null,
    end: null,
    categories: [],
    categoryId: categoryId,
    type: null,
  });

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "updatedAt",
    sortOrder: -1,
    categoryId: categoryId,
  });

  useEffect(() => {
    if (categoryId) {
      getChildCategories();
    } else {
      setFilterCategories(categories);
    }
  }, [categoryId]);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter, categoryId]);
  useEffect(() => {
    getProductType();
  }, []);

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const getChildCategories = async () => {
    try {
      const childCategories: any = await CategoryService.getByParent({
        params: categoryId,
      });
      if (childCategories?.length > 0) {
        setFilterCategories(childCategories);
      } else {
        setFilterCategories(categories);
      }
    } catch (error) {
      setFilterCategories(categories);
    }
  };

  const getProductType = async () => {
    try {
      const res: any = await CategoryService.getAll({
        query: {
          isTree: true,
          group: "product_category_type",
          ...globalFilter,
          status: "enable",
        },
      });

      setProductType(res);
    } catch (error) {}
  };
  const transformData = (data) => {
    return data.map((item) => ({
      key: item.key,
      keyVal: item.key,
      label: item.name,
      children: item.children ? transformData(item.children) : [],
    }));
  };
  const nodes = transformData(productType);

  const loadData = async () => {
    try {
      const categories = [];
      for (const selected in globalFilter.categories) {
        if (globalFilter.categories[selected].checked) {
          categories.push(selected);
        }
      }
      const res: any = await ProductService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          categories,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    loadData();
    refDialogDetail.current.close();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // Reward
  const onCancelDialogReward = () => {
    refDialogReward.current.close();
  };

  const onSumitDialogReward = () => {
    refReward.current.submit();
  };

  const setLoadingSaveReward = (flg) => {
    refDialogReward.current.setLoading(flg);
  };

  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await ProductService.deleteProducts({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Product Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const editProduct = async (data, type) => {
    try {
      setDetail({ ...data });
      if (type === "detail") {
        refDialogDetail.current.show();
      } else {
        refDialogReward.current.show();
      }
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });

    setData(newData);

    if (updateRow) {
      await ProductService.updateProducts({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  const onChangePublic = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });

    setData(newData);

    if (updateRow) {
      await ProductService.updateProducts({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  const formatCategories = (data) => {
    const notParents = [];
    let parents = data.filter((i) => {
      if (i?.parent_id) {
        notParents.push(i);
        return null;
      } else {
        return i;
      }
    });

    if (parents?.length === 0 && categoryId?.length > 0) {
      return data.map((item: any) => ({
        ...item,
        key: item._id,
        label: item.name,
      }));
    }

    const recursive = (item) => {
      const children = notParents.filter((i) => item._id === i.parent_id);
      return {
        ...item,
        key: item._id,
        label: item.name,
        children: children.map((j) => recursive(j)),
      };
    };

    return parents.map((i) => recursive(i));
  };

  const convertWrongTextSearch = async () => {
    try {
      setLoading(true);
      const res: any = await ProductService.getAndConvertWrongProducts({});
      showToast(toast, "success", `Update for ${res?.length || 0} products`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Update categories all products"
          icon="pi pi-update"
          className="mr-2 inline-block"
          onClick={convertWrongTextSearch}
          disabled={loading}
        />
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-3">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(PRODUCT_STATUS).map((item) => ({
              name: item,
              value: PRODUCT_STATUS[item],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-3">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-6">
          <TreeSelect
            value={globalFilter.categories}
            options={formatCategories(filterCategories)}
            onChange={(e: any) => onSearch("categories", e?.value)}
            placeholder="Categories"
            className={"w-full"}
            filter
            selectionMode="checkbox"
          />
        </div>
        <div className="col-12 md:col-6">
          <TreeSelect
            placeholder="Select Type"
            inputId="treeselect"
            value={globalFilter.type}
            onChange={(e) => onSearch("type", e.value)}
            options={[selectEmpty, ...nodes]}
            className="w-full"
            filter
          />
        </div>
      </div>
    </Panel>
  );

  const onChangeOrder = debounce(async (id: string, value: number) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === id) {
        e["order"] = value;
        updateRow = e;
      }
      return e;
    });

    setData(newData);

    if (updateRow) {
      await ProductService.updateProducts({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  }, 700);

  return (
    <div className="grid View-demo">
      <div className="col-12 ">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              alignFrozen="left"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => (
                <div className="actions ">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-sm p-button-success mr-1"
                    onClick={() => editProduct(rowData, "detail")}
                  />
                  <Button
                    icon="bx bxs-gift"
                    className="p-button-rounded p-button-sm p-button-success mr-1"
                    onClick={() => editProduct(rowData, "reard")}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-sm p-button-warning mr-1"
                    onClick={() => confirmDelete(rowData)}
                  />
                  <Button
                    icon="bx bx-show"
                    className="p-button-rounded p-button-sm "
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_CLIENT_DOMAIN}/posts/${rowData?.post_id}`
                      )
                    }
                  />
                </div>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Order"
              field="order"
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData?.order}
                  options={options}
                  onChange={(_, value) => {
                    onChangeOrder(rowData?._id, value);
                  }}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Image"
              body={(rowData) => {
                return (
                  <Image
                    src={getUrlImage(rowData?.info?.thumbnail)}
                    width="50"
                    height="50"
                    preview
                  />
                );
              }}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="status"
              header="Status"
              sortable
              body={(rowData, options) => (
                <StatusEditorCell
                  status={PRODUCT_STATUS}
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              field="is_public"
              header="Public"
              body={(rowData, options) => (
                <InputSwitch
                  checked={rowData.is_public}
                  onChange={(e) =>
                    onChangePublic(options, rowData, e.target.value)
                  }
                />
              )}
            />
            <Column
              field="owner"
              header="Owner"
              style={{ flexGrow: 1, flexBasis: "400px" }}
              body={(rowData) => (
                <span>
                  {rowData.user?.refer_code} -{" "}
                  {getStringLimit(rowData.user?.full_name, 30)}
                </span>
              )}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => <span>{rowData.name || ""}</span>}
            ></Column>
            <Column
              sortable
              field="updatedAt"
              header="Updated At"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => (
                <span>{formatLocalTime(rowData.updatedAt)}</span>
              )}
            ></Column>
            <Column
              header="Type"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => <span>{rowData.type || ""}</span>}
            ></Column>
            <Column
              field="stock"
              header="Stock"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => rowData.cal.stock}
            ></Column>
            <Column
              sortable
              field="cost"
              header="Cost"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => (
                <span>{convertNumberToVnd(rowData.cal.cost || 0)}</span>
              )}
            ></Column>
            <Column
              field="price"
              header="Price"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              sortable
              body={(rowData) => convertNumberToVnd(rowData.cal.price || 0)}
            ></Column>
            <Column
              field="amount_sold"
              header="Amount sold"
              style={{ flexGrow: 1, flexBasis: "130px" }}
              sortable
              body={(rowData) => formatNumber(rowData.cal.amount_sold)}
            ></Column>
            <Column
              field="qty_max_sell"
              header="Quantity max sell"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              sortable
              body={(rowData) => formatNumber(rowData.cal.qty_max_sell)}
            ></Column>

            <Column
              field="tax"
              header="Tax"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              sortable
              body={(rowData) => rowData.cal.tax}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <ProductDetail
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VDialog
            ref={refDialogReward}
            header="Reward"
            onSubmit={onSumitDialogReward}
          >
            <ProductReward
              ref={refReward}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveReward}
              onCancel={onCancelDialogReward}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

export default View;
