/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Button, InputText, InputTextarea, Toast } from "primereact";
import SettingService from "services/settings";
import { nanoid } from "nanoid";
import { showToast } from "utils/common";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";

const shopLayouts = [
  {
    name: "Option 1",
    description: "Category on top",
    value: 0,
  },
  {
    name: "Option 2",
    description: "Category on left",
    value: 1,
  },
];

const bannerRightLayouts = [
  {
    name: "Option 1",
    description: "Show on container",
    value: "show_on_container",
  },
  {
    name: "Option 2",
    description: "Show on right bar",
    value: "show_on_right_bar",
  },
];

const View = () => {
  const toast = useRef(null);
  const [enable, setEnable] = useState(true);
  const [enableComment, setEnableComment] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [state, setState] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [siteLogo, setSiteLogo] = useState();
  const [sidebarLogo, setSidebarLogo] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [allowTrading, setAllowTrading] = useState(false);
  const [shopLayout, setShopLayout] = useState(null);
  const [bannerRightLayout, setBannerRightLayout] = useState(null);
  const [urlHome, setUrlHome] = useState("");
  const [postOrigin, setPostOrigin] = useState("");
  const [imageMaintenance, setImageMainenace] = useState();
  const [imageMaintenanceMobile, setImageMainenaceMobile] = useState();
  const [timeMaintenance, setTimeMaintenance] = useState<{
    start: any;
    end: any;
  }>();
  const [logo, setLogo] = useState();

  const types = [
    { key: "text", name: "Ký tự" },
    { key: "number", name: "Số" },
    { key: "media", name: "Ảnh" },
  ];

  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    data_type: null,
  });

  const handleAdd = () => {
    const index = nanoid();
    setFilterState((filterState) => [
      ...filterState,
      { index: index, editable: true },
    ]);
    setState((state) => [...state, { index: index, editable: true }]);
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "general",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : [];
          setEnable(value?.post || false);
          setEnableComment(value?.enable_comment || false);
          setMaintenance(value?.maintenance || false);
          setSiteLogo(value?.site_logo);
          setSidebarLogo(value?.sidebar_logo);
          setState(value?.site || []);
          setUrlHome(value?.url_home || "");
          setPostOrigin(value?.post_origin || "");
          setFilterState(value?.site || []);
          setData(value);
          setAllowTrading(value?.allowTrading || false);
          setShopLayout(value?.shop_layout || null);
          setBannerRightLayout(value?.banner_right_layout || null);
          setImageMainenace(value?.image_mainenance);
          setImageMainenaceMobile(value?.image_mainenance_mobile);
          setLogo(value?.logo);
          setTimeMaintenance({
            start: new Date(value?.start_time_maintenance),
            end: new Date(value?.end_time_maintenance),
          });
        }
      }
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "general",
          value: {
            post: enable,
            enable_commnent: enableComment,
            maintenance: maintenance,
            site_logo: siteLogo,
            sidebar_logo: sidebarLogo,
            site: state,
            allowTrading,
            shop_layout: shopLayout,
            banner_right_layout: bannerRightLayout,
            url_home: urlHome,
            post_origin: postOrigin,
            image_mainenance: imageMaintenance,
            image_mainenance_mobile: imageMaintenanceMobile,
            logo: logo,
            enable_comment: enableComment,
            start_time_maintenance: timeMaintenance?.start,
            end_time_maintenance: timeMaintenance?.end,
            // site_media: data?.data_type === "media" ? data?.site_media : "",
          },
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const changeHandler = (value, name, index) => {
    const arr = filterState.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setFilterState(arr);

    const arrState = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arrState);
  };

  const changeFilter = (value, name) => {
    let _details = { ...globalFilter };
    _details[`${name}`] = value;
    setGlobalFilter(_details);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilter();
  }, [data]);

  useEffect(() => {
    setFilter();
  }, [globalFilter]);

  const setFilter = () => {
    if (globalFilter?.search && globalFilter?.data_type) {
      let newState = [];
      newState = data?.site?.filter(
        (l) =>
          l.key?.includes(globalFilter?.search) &&
          l.data_type === globalFilter?.data_type
      );
      setFilterState(newState);
    }
    if (!globalFilter?.search) {
      let newState = [];
      newState = data?.site?.filter(
        (l) => l.data_type === globalFilter?.data_type
      );
      setFilterState(newState);
    }
    if (!globalFilter?.data_type) {
      let newState = [];
      newState = data?.site?.filter((l) =>
        l.key?.includes(globalFilter?.search)
      );
      setFilterState(newState);
    }
    if (!globalFilter?.search && !globalFilter?.data_type) {
      setFilterState(state);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>General</h4>
          </div>
          <Panel
            header="Maintenance"
            toggleable
            collapsed={false}
            className="col-12"
          >
            <div className="grid px-2">
              <div className="col-12 md:col-2">
                <label htmlFor="id">Enable</label>
                <div>
                  <InputSwitch
                    checked={maintenance}
                    onChange={(e) => setMaintenance(e.value)}
                  />
                </div>
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="name">Image PC</label>
                <VUpload
                  urlFile={imageMaintenance}
                  setUrlFile={(file) => setImageMainenace(file)}
                />
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="name">Image Mobile</label>
                <VUpload
                  urlFile={imageMaintenanceMobile}
                  setUrlFile={(file) => setImageMainenaceMobile(file)}
                />
              </div>

              <div className="col-12 md:col-3">
                <label>Start time</label>
                <Calendar
                  className="w-full"
                  id="time24"
                  placeholder="Start Date"
                  value={timeMaintenance?.start}
                  onChange={(e: any) => {
                    setTimeMaintenance((prev) => ({
                      ...prev,
                      start: e?.value,
                    }));
                  }}
                  showTime
                  showSeconds
                  showButtonBar
                />
              </div>
              <div className="col-12 md:col-3">
                <label>End time</label>
                <Calendar
                  className="w-full"
                  id="time24"
                  placeholder="End Date"
                  value={timeMaintenance?.end}
                  onChange={(e: any) => {
                    setTimeMaintenance((prev) => ({ ...prev, end: e?.value }));
                  }}
                  showTime
                  showSeconds
                  showButtonBar
                />
              </div>
            </div>
          </Panel>

          <div className="field col-12">
            <label htmlFor="id">URL Home</label>
            <div>
              <InputText
                id="key"
                className="w-full"
                value={urlHome}
                onChange={(e) => setUrlHome(e.target.value)}
                placeholder="URL Home"
              />
            </div>
          </div>
          <div className="field col-12">
            <label htmlFor="id">Post origin</label>
            <div>
              <InputText
                id="post_origin"
                className="w-full"
                value={postOrigin}
                onChange={(e) => setPostOrigin(e.target.value)}
                placeholder="Post origin"
              />
            </div>
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="id">Shop layout</label>
            <div>
              <DataTable value={shopLayouts} tableStyle={{ minWidth: "50rem" }}>
                <Column
                  field="checked"
                  body={(row) => (
                    <RadioButton
                      name="shop_layout"
                      value={row.value}
                      onChange={() => setShopLayout(row.value)}
                      checked={shopLayout === row.value}
                    />
                  )}
                ></Column>
                <Column field="name" header="Name"></Column>
                <Column field="description" header="Description"></Column>
              </DataTable>
            </div>
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="id">Banner right layout</label>
            <div>
              <DataTable
                value={bannerRightLayouts}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  field="checked"
                  body={(row) => (
                    <RadioButton
                      name="banner_right_layout"
                      value={row.value}
                      onChange={() => setBannerRightLayout(row.value)}
                      checked={bannerRightLayout === row.value}
                    />
                  )}
                ></Column>
                <Column field="name" header="Name"></Column>
                <Column field="description" header="Description"></Column>
              </DataTable>
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="id">Allows direct trading</label>
            <div>
              <InputSwitch
                checked={allowTrading}
                onChange={(e) => setAllowTrading(e.value)}
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="id">Post</label>
            <div>
              <InputSwitch
                checked={enable}
                onChange={(e) => setEnable(e.value)}
              />
            </div>
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="id">Comment</label>
            <div>
              <InputSwitch
                checked={enableComment}
                onChange={(e) => setEnableComment(e.value)}
              />
            </div>
          </div>
          <div className="field col-6">
            <label htmlFor="name">Site Logo</label>
            <VUpload
              urlFile={siteLogo}
              setUrlFile={(file) => setSiteLogo(file)}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="name">Sidebar Logo</label>
            <VUpload
              urlFile={sidebarLogo}
              setUrlFile={(file) => setSidebarLogo(file)}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="name">Logo</label>
            <VUpload urlFile={logo} setUrlFile={(file) => setLogo(file)} />
          </div>

          <div className="grid mb-3">
            <div className="field col-12 md:col-2">
              <label htmlFor="id">Key filter</label>
              {/* <span className="p-float-label"> */}
              <InputText
                id="key"
                className="w-full"
                value={globalFilter.search}
                onChange={(e) => changeFilter(e.target.value, "search")}
                placeholder="Key Search"
              />
              {/* <label htmlFor="id">Key Search</label> */}
              {/* </span> */}
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="id">Data type filter</label>
              {/* <span className="w-full p-float-label"> */}
              <Dropdown
                value={globalFilter?.data_type}
                options={types}
                optionLabel="name"
                optionValue="key"
                onChange={(e) => changeFilter(e.target.value, "data_type")}
                placeholder="Data type"
                className="w-full"
                showClear
              />
              {/* <label htmlFor="id">Data type</label> */}
              {/* </span> */}
            </div>
          </div>

          {filterState?.map((item) => {
            return (
              <div className="grid mb-3" key={item.index}>
                <div className="field col-12 md:col-2">
                  <span className="p-float-label">
                    <InputText
                      id="key"
                      className="w-full"
                      value={item.key}
                      onChange={(e) =>
                        changeHandler(e.target.value, "key", item.index)
                      }
                      disabled={!item?.editable}
                    />
                    <label htmlFor="id">Key</label>
                  </span>
                </div>
                <div className="field col-12 md:col-2">
                  <span className="w-full p-float-label">
                    <Dropdown
                      value={item?.data_type}
                      options={types}
                      optionLabel="name"
                      optionValue="key"
                      onChange={(e) =>
                        changeHandler(e.target.value, "data_type", item.index)
                      }
                      placeholder="Data type"
                      className="w-full"
                    />
                    <label htmlFor="id">Data type</label>
                  </span>
                </div>
                <div
                  className="field col-12 md:col-4"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="w-full p-float-label">
                    {item?.data_type === "media" && (
                      <VUpload
                        urlFile={item.site_media}
                        setUrlFile={(file) =>
                          changeHandler(file, "site_media", item.index)
                        }
                      />
                    )}
                    {(!item?.data_type ||
                      item?.data_type === null ||
                      item?.data_type === "text") && (
                      <InputTextarea
                        id="content"
                        className="w-full"
                        value={item.content}
                        onChange={(e) =>
                          changeHandler(e.target.value, "content", item.index)
                        }
                      />
                    )}
                    {item?.data_type === "number" && (
                      <InputNumber
                        id="content"
                        className="w-full"
                        value={item.content}
                        onChange={(e) =>
                          changeHandler(e.value, "content", item.index)
                        }
                      />
                    )}
                    <label htmlFor="content">Content</label>
                  </span>
                </div>

                <div
                  className="field col-12 md:col-4"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="w-full p-float-label">
                    <InputTextarea
                      id="description"
                      className="w-full"
                      value={item.description}
                      onChange={(e) =>
                        changeHandler(e.target.value, "description", item.index)
                      }
                    />
                    <label htmlFor="description">Description</label>
                  </span>
                </div>
              </div>
            );
          })}
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
